<template>
    <div class="row">
        <div class="col">
            <label class="labelBigAnalytic">{{resumeoptions.eventsCreated}}</label>
            <p>Eventos Abiertos</p>
        </div>
        <div class="col">
            <label class="labelBigAnalytic">{{resumeoptions.eventsLaunched}}</label>
            <p>
                Eventos En Progreso</p>
        </div>
        <div class="col">
            <label class="labelBigAnalytic">{{resumeoptions.eventsClosed}}</label>
            <p>
                Eventos Cerrados</p>
        </div>
        <div class="col">
            <label class="labelBigAnalytic">{{resumeoptions.products}}</label>
            <p>
                Productos Creados</p>
        </div>
        <div class="col">
            <label class="labelMediumAnalytic">{{resumeoptions.last_event_launched.name}}</label>
            <p>
                Ultimo Evento Lanzado</p>
        </div>
        <div class="col">
            <label class="labelMediumAnalytic">{{resumeoptions.last_event_closed.name}}</label>
            <p>
                Ultimo Evento Cerrado</p>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import {SpringSpinner} from 'epic-spinners'
    import {
        mapState,
        mapActions
    } from 'vuex'

    export default {
        name: 'AnalyticEventResume',
        components: {
            SpringSpinner,

        },
        data() {
            return {
                resumeoptions: {
                    eventsCreated: 0,
                    eventsLaunched: 0,
                    eventsClosed: 0,
                    products: 0,
                    last_event_launched: {name: ''},
                    last_event_closed: {name: ''},


                },
            }
        },
        computed: {
            ...mapState({
                me: state => state.auth.me,
                company: state => state.auth.company,
            }),

        },
        mounted() {
            console.log('COMPANY: ' + this.company.id);
            // if(!this.isEmpty(this.company)){
            this.loadDashboardEventResume(this.company.id)
                .then((data) => {
                    // this.resumeoptions = [];
                    this.resumeoptions = data;
                })
                .catch(data => {
                    this.error = data.message
                })
            //  }


        },
        methods: {
            ...mapActions([
                'loadDashboardEventResume'

            ]),
            newEvent() {
                this.$router.push('event/new')
            },
            isEmpty(obj) {
                for (var key in obj) {
                    if (obj.hasOwnProperty(key))
                        return false;
                }
                return true;
            }

        }
    }
</script>

<style lang="scss">

    .color-icon-label-table {
        td:first-child {
            width: 1rem;
        }
    }

    .labelBigAnalytic {
        font-size: 36px;
    }

    .labelMediumAnalytic {
        font-size: 25px;
    }
</style>
